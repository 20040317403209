


















































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import { MetaModule as metaStore, VehicleEntryModule as entryStore } from "@/store/modules";

@Component({
    components: {
        DetailTag,
    }
})
export default class OneTimePermitDetail extends Vue {
    
    get AdminRouter() {
        return AdminRouter;
    }

    async created() {
        const id = this.$route.query["id"];
        await entryStore.getVehicleEntryById(id);
    }

    get vehicleEntry() {
        return entryStore.vehicleEntry;
    }
}
